import {
	GET_TRANSACTION_HISTORY,
	SET_ORDERS_PROP,
	IMPORT_BASKET,
	LIST_PAYMENT_CARDS,
	ADD_PAYMENT_CARD,
	REMOVE_PAYMENT_CARD,
	CREATE_ORDER,
	GET_ORDER_HISTORY,
	ADD_SCANNED_CARD,
	ADD_DELIVERY_ADDRESS,
	SET_DELIVERY_ADDRESS,
	POSTCODE_CHECK,
	SET_POSTCODE_DATA,
	GET_NEAREST_LOCATION,
	LOCATION_CODE_CHECK,
	SET_LOCATION_CODE_DATA,
	ADD_PICKUP_POINT,
	SET_PICK_UP_POINT,
	REMOVE_DELIVERY_ADDRESS,
	UPDATE_PAYMENT_INFO,
	CHECK_CANCEL_ORDER,
	SEND_GIFT_VOUCHER,
	REDEEM_GIFT_VOUCHER,
	CLEAR_GIFT_VOUCHER_DATA,
	GET_SENT_GIFT_VOUCHERS,
	RESEND_GIFT_VOUCHER,
	UPDATE_GIFT_VOUCHER,
	CREATE_STRIPE_ORDER,
	UPDATE_STRIPE_ORDER,
	SET_BILL_STATUS,
	SEND_TABLE_BILL,
	SEND_TABLE_PAYMENT_EMAIL,
	SELECT_TABLE_ITEM,
	SET_TABLE_PAY_METHOD,
	CREATE_YOCO_ORDER,
	BOOK_DELIVERY_DRIVER,
} from './constants';
import { UPDATE_PROFILE, GET_PROFILE } from '../constants';
import { take, call, put, select, delay, fork, cancel, takeLatest, takeEvery } from 'redux-saga/effects';
import { loading } from '../common/sagas';
import api from '../../lib/api';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import { showToast, showAlert, updateDriverCountdown } from '../actions';
import { isDefined, forwardTo, isEmptyObject } from '../../lib/utils';
import { translateSaga } from '../common/sagas';
import Stripe from '../../lib/stripe';
import { SET_COMMON_MODAL, SET_COMMON_PROP } from '../common/constants';
import { SET_RESTAURANT_PROP, SET_TABLE_DATA } from '../restaurants/constants';
import asyncStorage from '../../lib/asyncStorage';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import moment from 'moment';

/* transaction history Saga */
export const getTransactionHistoryFlow = function* () {
	let flags = getConfig()?.flags;
	if (!flags || isEmptyObject(flags)) {
		const config = yield call(api.getFrontEndAppConfig);
		flags = config?.front_end_app_config?.flags;
	}
	const { hasOrdering, hasLoyalty, disableHistoryPagination } = flags;
	while (true) {
		const { page, skipOrders, skipLoyalty } = yield take(GET_TRANSACTION_HISTORY);
		const paginationPage = disableHistoryPagination ? null : page;
		yield call(loading, function* () {
			// call transaction and order history
			if (hasOrdering && !skipOrders) {
				yield call(getOrderHistoryData, paginationPage);
			}
			if (hasLoyalty && !skipLoyalty) {
				const history = yield call(api.getHistory, paginationPage);
				yield put({ type: SET_ORDERS_PROP, key: 'history', value: history });
			}
		});
	}
};

export const importBasket = function* () {
	while (true) {
		yield take(IMPORT_BASKET);
		yield call(Basket.import);
	}
};

export const getPaymentCardsFlow = function* () {
	while (true) {
		yield take(LIST_PAYMENT_CARDS);
		yield call(loading, function* () {
			const cards = yield call(api.getPaymentCards);
			yield put({ type: SET_ORDERS_PROP, key: 'cards', value: cards });
		});
	}
};

export const addPaymentCardsFlow = function* () {
	while (true) {
		const action = yield take(ADD_PAYMENT_CARD);
		const { name, options } = action;
		let result = null;
		if (getConfig().payment === 'judopay') {
			options.name = name;
			yield call(loading, function* () {
				try {
					const savedCards = yield call(api.addPaymentCard, options);
					yield put({ type: SET_ORDERS_PROP, key: 'cards', value: savedCards });
					yield put(showToast(yield call(translateSaga, 'Card added successfully'), 'success'));

					yield put({ type: GET_PROFILE });
					if (options.cb) {
						yield call(options.cb);
					}
				} catch (e) {
					yield put(showToast(yield call(translateSaga, 'Problem adding card'), 'warning'));
					throw e;
				}
			});
		} else {
			if (options.stripe) {
				result = yield call(options.stripe.createToken);
			}
			yield call(loading, function* () {
				if (options.stripe) {
					try {
						if (result.error) {
							yield put(showToast(yield call(translateSaga, result.error.message), 'warning'));
						} else {
							const payment_token = result.token.id;

							const savedCards = yield call(api.addPaymentCard, {
								payment_token,
								name,
							});
							yield put({ type: SET_ORDERS_PROP, key: 'cards', value: savedCards });
							yield put(showToast(yield call(translateSaga, 'Card added successfully'), 'success'));

							//everytime when user add card that card will be default card. Because of that we call get profile
							yield put({ type: GET_PROFILE });

							if (options.cb) {
								yield call(options.cb);
							}
						}
					} catch (e) {
						yield put(showToast(yield call(translateSaga, 'Problem adding card'), 'warning'));
						throw e;
					}
				}
			});
		}
	}
};

export const addScannedCard = function* () {
	while (true) {
		const action = yield take(ADD_SCANNED_CARD);
		yield call(loading, function* () {
			const { options, payment_token, name } = action;
			const savedCards = yield call(api.addPaymentCard, { payment_token, name });
			yield put({ type: SET_ORDERS_PROP, key: 'cards', value: savedCards });
			yield put(showToast('Card added successfully', 'success'));

			//everytime when user add card that card will be default card. Because of that we call get profile
			// yield put({ type: GET_PROFILE })
			if (options.cb) {
				yield call(options.cb);
			}
		});
	}
};

export const removePaymentCardsFlow = function* () {
	while (true) {
		const action = yield take(REMOVE_PAYMENT_CARD);
		yield call(loading, function* () {
			const { cardToken, options } = action;

			const data = {
				payment_token: cardToken,
			};
			const result = yield call(api.removePaymentCard, data);
			if (result.error) {
				yield put(showToast(yield call(translateSaga, result.error.message), 'warning'));
			} else {
				yield put({ type: SET_ORDERS_PROP, key: 'cards', value: result.data });
				yield put(showToast(yield call(translateSaga, 'Card removed successfully'), 'success'));
			}
			if (options.cb) {
				yield call(options.cb);
			}

			const store = yield select();
			if (store.profile && store.profile.profile && store.profile.profile.cardToken && store.profile.profile.cardToken === cardToken) {
				// If the user want to delete default card then change default card to the first one.
				// When user delete last card then put null
				yield put({
					type: UPDATE_PROFILE,
					skipAlert: true,
					data: {
						cardToken: store.orders.cards && store.orders.cards.length > 0 ? store.orders.cards[0].id : null,
					},
				});
			}
		});
	}
};

const checkIntentResult = function* (intentResult) {
	// Stripe.checkIntentResult i maybe already called
	const status = isDefined(intentResult.isValid) ? intentResult : yield call(Stripe.checkIntentResult, intentResult);
	if (status.isValid) {
		yield put(showToast(status.message, 'success'));
	} else {
		yield put(showAlert(status.message));
	}
	return status;
};

const updatePaymentInfo = function* (intentResult, paymentType, createdOrder) {
	const paymentInfo = {
		intentResult,
		paymentMethod: paymentType,
		orderId: createdOrder.id,
	};
	yield put({ type: UPDATE_PAYMENT_INFO, paymentInfo });
};

const resetBasket = (giftVoucherData = false, billPay = false) => {
	if (!billPay) {
		Basket.reset();
	}
	if (!giftVoucherData && !billPay) {
		forwardTo('/order-completed', { completedOrder: true });
	}
	if (billPay) {
		forwardTo('/bill-completed', { completedOrder: true });
	}
};
export const updateStripeOrderFlow = function* () {
	while (true) {
		const { orderId } = yield take(UPDATE_STRIPE_ORDER);
		yield call(api.updateStripeOrder, { orderId });
	}
};
const createStripeOrderFlow = function* (action) {
	const { paymentType, paymentWebType, cb } = action;
	const store = yield select();
	const giftVoucherData = store.orders.giftVoucherData;
	if (Basket.last_subtotal !== parseFloat(Basket.calculateSubTotal()) && getConfig().flags.hasTaxBreakdown && !giftVoucherData) {
		yield call(forwardTo, '/order-summary');
		return;
	}

	yield call(loading, function* () {
		let orderData = Basket.parseBasketData(paymentType, paymentWebType);
		const store = yield select();
		orderData.paymentToken = store.orders.paymentToken ? store.orders.paymentToken : null;
		//create order and init stripe intent
		const result = yield call(api.createStripeOrder, orderData);
		if (giftVoucherData) {
			giftVoucherData.order_id = result.data.order.id;
		}
		// process payment intent
		if (result.data?.error || result.error) {
			if (result.data?.type === 'is_collection_time_available') {
				const restaurants = yield call(api.getRestaurants);
				yield put({
					type: SET_RESTAURANT_PROP,
					key: 'restaurants',
					value: restaurants,
				});
				yield put({
					type: SET_COMMON_MODAL,
					modal: 'deliveryTimeModalOpen',
					value: true,
				});
			} else {
				yield put(showToast('Order create error', 'warning'));
			}
		} else {
			Basket.reset();
			window.open(result.data.checkout.url, '_self');
		}
	});
};

const createYocoOrderFlow = function* (action) {
	const { paymentType, paymentWebType, cb } = action;
	const store = yield select();
	const giftVoucherData = store.orders.giftVoucherData;
	if (Basket.last_subtotal !== parseFloat(Basket.calculateSubTotal()) && getConfig().flags.hasTaxBreakdown && !giftVoucherData) {
		yield call(forwardTo, '/order-summary');
		return;
	}
	const { disableHistoryPagination } = getConfig()?.flags;
	const page = disableHistoryPagination ? null : 1;
	yield call(loading, function* () {
		let orderData = Basket.parseBasketData(paymentType, paymentWebType);
		const store = yield select();
		orderData.paymentToken = store.orders.paymentToken ? store.orders.paymentToken : null;
		//create order and init stripe intent
		const result = yield call(api.createYocoOrder, orderData);
		if (giftVoucherData) {
			giftVoucherData.order_id = result.data.order.id;
		}
		// process payment intent
		if (result.data?.error || result.error) {
			if (result.data?.type === 'is_collection_time_available') {
				const restaurants = yield call(api.getRestaurants);
				yield put({
					type: SET_RESTAURANT_PROP,
					key: 'restaurants',
					value: restaurants,
				});
				yield put({
					type: SET_COMMON_MODAL,
					modal: 'deliveryTimeModalOpen',
					value: true,
				});
			} else {
				yield put(showToast('Order create error', 'warning'));
			}
		} else {
			if (result.data.yoco) {
				window.open(result.data.yoco.redirectUrl, '_self');
			} else {
				Basket.reset();
				yield call(getOrderHistoryData, page);
				forwardTo('/order-completed');
			}
		}
	});
};
const createOrderFlow = function* (action) {
	const { paymentType, paymentWebType, cb } = action;
	const store = yield select();
	const giftVoucherData = store.orders.giftVoucherData;
	if (Basket.last_subtotal !== parseFloat(Basket.calculateSubTotal()) && getConfig().flags.hasTaxBreakdown && !giftVoucherData) {
		yield call(forwardTo, '/order-summary');
		return;
	}
	yield call(loading, function* () {
		let orderData = Basket.parseBasketData(paymentType, paymentWebType);
		const store = yield select();
		orderData.paymentToken = store.orders.paymentToken ? store.orders.paymentToken : null;
		//create order and init stripe intent
		const result = yield call(api.createOrder, orderData);
		if (giftVoucherData) {
			giftVoucherData.order_id = result.data.order.id;
		}
		// process payment intent
		if (result.data?.error || result.error) {
			if (result.data?.type === 'is_collection_time_available') {
				const restaurants = yield call(api.getRestaurants);
				yield put({
					type: SET_RESTAURANT_PROP,
					key: 'restaurants',
					value: restaurants,
				});
				yield put({
					type: SET_COMMON_MODAL,
					modal: 'deliveryTimeModalOpen',
					value: true,
				});
			} else {
				yield put(showAlert('Order create error'));
			}
		} else if (paymentType === 'collectedPay') {
			yield call(forwardTo, '/order-completed', { completedOrder: true });
			const createdOrder = result.data.order;
			Basket.reset();
			const store = yield select();
			yield put({
				type: SET_ORDERS_PROP,
				key: 'orderHistory',
				value: [createdOrder, ...(store.orders.orderHistory || [])],
			});
		} else if (paymentType === 'driverPay') {
			yield call(forwardTo, '/order-completed', { completedOrder: true });
			const createdOrder = result.data.order;
			Basket.reset();
			const store = yield select();
			yield put({
				type: SET_ORDERS_PROP,
				key: 'orderHistory',
				value: [createdOrder, ...(store.orders.orderHistory || [])],
			});
		} else {
			const createdOrder = result.data.order;
			const clientSecret = result.data.client_secret;
			if (!clientSecret || clientSecret === '') {
				yield put(showAlert('Payment error.'));
			} else {
				if (getConfig().payment === 'judopay') {
					if (clientSecret.result && clientSecret.result === 'Declined') {
						yield put(showAlert('Payment error.\n' + (clientSecret.message || '')));
					} else {
						yield call(resetBasket, giftVoucherData);
						if (giftVoucherData) {
							yield put({
								type: SEND_GIFT_VOUCHER,
								data: giftVoucherData,
							});
						}
					}
				} else {
					if (clientSecret === '-1') {
						// total for order is: 0
						yield call(checkIntentResult, {
							status: 'succeeded',
						});
						yield call(resetBasket, giftVoucherData);
						if (giftVoucherData) {
							yield put({
								type: SEND_GIFT_VOUCHER,
								data: giftVoucherData,
							});
						}
						// yield call(resetBasket);
					} else if (paymentType === 'webPayment') {
						yield put({
							type: SET_ORDERS_PROP,
							key: 'clientSecret',
							value: clientSecret,
						});
						if (cb) {
							yield call(cb, clientSecret, resetBasket, giftVoucherData);
						}
					} else {
						try {
							let intentResult;
							if (paymentType === 'google') {
								// pay with google pay
								intentResult = yield call(Stripe.payWithGooglePay, clientSecret, orderData._total);
								yield call(updatePaymentInfo, intentResult, paymentType, createdOrder);
								const checkedIntentResult = yield call(checkIntentResult, intentResult);
								if (checkedIntentResult.isValid) {
									if (giftVoucherData) {
										yield put({
											type: SEND_GIFT_VOUCHER,
											data: giftVoucherData,
										});
									}
									yield call(resetBasket, giftVoucherData);
								}
							} else if (paymentType === 'apple') {
								// pay with apple pay
								const store = yield select();
								intentResult = yield call(Stripe.payWithApplePay, clientSecret, store.profile.profile);
								yield call(updatePaymentInfo, intentResult, paymentType, createdOrder);
								const checkedIntentResult = yield call(checkIntentResult, intentResult);
								yield call(resetBasket, giftVoucherData);

								if (giftVoucherData && checkedIntentResult.isValid) {
									yield put({
										type: SEND_GIFT_VOUCHER,
										data: giftVoucherData,
									});
								}
							} else {
								// pay with regular payment card
								const intentResult = yield call(Stripe.confirmPaymentIntent, clientSecret, {
									paymentMethodId: orderData.payment_token,
								});
								const checkedIntentResult = yield call(checkIntentResult, intentResult);
								if (checkedIntentResult.isValid) {
									yield call(resetBasket, giftVoucherData);
								}
								if (giftVoucherData && checkedIntentResult.isValid) {
									yield put({
										type: SEND_GIFT_VOUCHER,
										data: giftVoucherData,
									});
								}
							}
						} catch (e) {
							yield put(showAlert('Payment error.\n' + (e.message || '')));
						}
					}
				}
			}

			//update ordr history with new added order
			const store = yield select();
			yield put({
				type: SET_ORDERS_PROP,
				key: 'orderHistory',
				value: [createdOrder, ...(store.orders.orderHistory || [])],
			});
		}
	});
};
export function* orderCreationSagas() {
	yield takeLatest(CREATE_ORDER, createOrderFlow);
	yield takeLatest(CREATE_YOCO_ORDER, createYocoOrderFlow);
	yield takeLatest(CREATE_STRIPE_ORDER, createStripeOrderFlow);
}

const getOrderHistoryData = function* (page) {
	const orderHistory = yield call(api.getOrderHistory, page);
	yield put({ type: SET_ORDERS_PROP, key: 'orderHistory', value: orderHistory });
};

export const getOrderHistoryFlow = function* () {
	while (true) {
		const action = yield take(GET_ORDER_HISTORY);
		const loading = isDefined(action.loading) ? action.loading : true;
		const { disableHistoryPagination } = getConfig()?.flags;
		const page = disableHistoryPagination ? null : 1;
		if (loading) {
			yield call(loading, function* () {
				yield call(getOrderHistoryData, page);
			});
		} else {
			yield call(getOrderHistoryData, page);
		}
	}
};

export const addDeliveryAddressFlow = function* () {
	while (true) {
		const action = yield take(ADD_DELIVERY_ADDRESS);
		const { deliveryAddress, flag } = action;
		yield call(loading, function* () {
			try {
				const address = yield call(api.addDeliveryAdress, deliveryAddress);
				yield put({
					type: SET_DELIVERY_ADDRESS,
					deliveryAddress: JSON.parse(address.config.data),
				});
				yield put(showToast(yield call(translateSaga, 'Address added successfully'), 'success'));
				yield put({ type: GET_PROFILE });
			} catch (e) {
				yield put(showToast('Add address.\n' + (e.message || ''), 'danger'));
			}
		});
	}
};

export const postCodeCheckFlow = function* () {
	while (true) {
		const action = yield take(POSTCODE_CHECK);
		const { postcode, charter_delivery } = action;
		yield call(loading, function* () {
			try {
				const checkedCodeData = yield call(api.postCodeCheck, {
					postcode,
					charter_delivery,
				});
				yield put({ type: SET_POSTCODE_DATA, checkedCodeData });
				if (checkedCodeData.data.length < 1) {
					yield put(showToast(yield call(translateSaga, 'No location found'), 'warning'));
				}
			} catch (e) {
				yield put({ type: SET_POSTCODE_DATA, checkedCodeData: { data: {} } });
			}
		});
	}
};

export const getNearestLocationFlow = function* () {
	while (true) {
		const action = yield take(GET_NEAREST_LOCATION);
		const { postcode, charter_delivery, scheduled_delivery } = action;
		yield call(loading, function* () {
			try {
				const checkedCodeData = yield call(api.getNearestLocation, {
					postcode,
					charter_delivery,
					scheduled_delivery,
				});
				yield put({ type: SET_POSTCODE_DATA, checkedCodeData });
				if (checkedCodeData.data.length < 1) {
					yield put(showToast(yield call(translateSaga, 'No location found'), 'warning'));
				}
			} catch (e) {
				yield put({ type: SET_POSTCODE_DATA, checkedCodeData: { data: {} } });
			}
		});
	}
};

export const locationCodeCheckFlow = function* () {
	while (true) {
		const action = yield take(LOCATION_CODE_CHECK);
		const { locationCode } = action;
		try {
			const checkedLocationCodeData = yield call(api.locationCodeCheck, {
				location_code: locationCode,
			});
			yield put({ type: SET_LOCATION_CODE_DATA, checkedLocationCodeData });
		} catch (e) {
			yield put({ type: SET_LOCATION_CODE_DATA, checkedLocationCodeData: { data: [] } });
		}
	}
};

export const addPickupPointFlow = function* () {
	while (true) {
		const action = yield take(ADD_PICKUP_POINT);
		const { pickUpPoint, code } = action;
		yield call(loading, function* () {
			try {
				const point = yield call(api.addPickupPoint, { restaurant_id: pickUpPoint, code });
				let parsedData = JSON.parse(point.config.data);
				yield put({ type: SET_PICK_UP_POINT, pickUpPoint: parsedData.code });
				yield put(showToast(yield call(translateSaga, 'Pickup point added successfully'), 'success'));
				yield put({ type: GET_PROFILE });
				yield call(forwardTo, '/delivery-time');
			} catch (e) {
				yield put(showToast('Add address.\n' + (e.message || ''), 'danger'));
			}
		});
	}
};

export const removeDeliveryAddressFlow = function* () {
	while (true) {
		const action = yield take(REMOVE_DELIVERY_ADDRESS);
		yield put({ type: SET_ORDERS_PROP, key: 'removeAddressModal', value: false });
		yield call(loading, function* () {
			const { index } = action;
			const data = {
				id: index,
			};
			const result = yield call(api.removeDeliveryAddress, data);
			if (result.error) {
				yield put(showToast(yield call(translateSaga, result.error.message), 'warning'));
			} else {
				yield put(showToast(yield call(translateSaga, 'Delivery address removed successfully'), 'success'));
			}
			yield put({ type: GET_PROFILE });
		});
	}
};

export const checkCancelOrderFlow = function* () {
	while (true) {
		const action = yield take(CHECK_CANCEL_ORDER);
		yield put({ type: SET_ORDERS_PROP, key: 'cancelOrderModal', value: false });
		yield call(loading, function* () {
			const { orderId, restaurantId } = action;
			const data = {
				order_id: orderId,
				restaurant_id: restaurantId,
			};
			yield call(api.cancelOrder, data);
			yield put(showToast(yield call(translateSaga, 'Order successfully refunded'), 'success'));
		});
	}
};
export const sendGiftVoucherFlow = function* () {
	while (true) {
		const action = yield take(SEND_GIFT_VOUCHER);
		yield call(loading, function* () {
			const result = yield call(api.sendGiftVoucher, action.data);
			if (!result.error) {
				forwardTo('/gift-voucher', { voucher: action.data, voucherCreated: true });
				yield put({ type: CLEAR_GIFT_VOUCHER_DATA });
				yield put({ type: GET_SENT_GIFT_VOUCHERS });
			}
		});
	}
};
export const resendGiftVoucherFlow = function* () {
	while (true) {
		const action = yield take(RESEND_GIFT_VOUCHER);
		yield call(loading, function* () {
			const result = yield call(api.resendGiftVoucher, action.data);
			if (!result.error) {
				forwardTo('/sent-vouchers', { giftVoucherRedeemed: true });
				yield put({ type: CLEAR_GIFT_VOUCHER_DATA });
				yield put({ type: GET_SENT_GIFT_VOUCHERS });
			}
		});
	}
};
export const updateGiftVoucherFlow = function* () {
	while (true) {
		const action = yield take(UPDATE_GIFT_VOUCHER);
		yield call(loading, function* () {
			const result = yield call(api.updateGiftVoucher, action.data);
			if (!result.error) {
				forwardTo('/sent-vouchers', { giftVoucherUpdated: true });
				yield put({ type: CLEAR_GIFT_VOUCHER_DATA });
				yield put({ type: GET_SENT_GIFT_VOUCHERS });
			}
		});
	}
};
export const redeemGiftVoucherFlow = function* () {
	while (true) {
		const action = yield take(REDEEM_GIFT_VOUCHER);
		yield call(loading, function* () {
			const result = yield call(api.redeemGiftVoucher, action.data);
			if (!result.error) {
				// forwardTo('/gift-vouchers', { giftVoucherRedeemed: true });
				yield put({
					type: 'SET_REDEEMED_GIFT_VOUCHER',
					value: result.data,
				});
			}
		});
	}
};
export const getSentGiftVouchersFlow = function* () {
	while (true) {
		yield take(GET_SENT_GIFT_VOUCHERS);
		yield call(loading, function* () {
			let result = yield call(api.getSentGiftVouchers);
			result.sort((a, b) => b.id - a.id);
			yield put({
				type: 'SET_SENT_GIFT_VOUCHERS',
				value: result,
			});
		});
	}
};

export const setTablePayMethodFlow = function* () {
	while (true) {
		const action = yield take(SET_TABLE_PAY_METHOD);

		yield call(loading, function* () {
			const { pay_method, table_bill_id, total_members_to_pay = 0 } = action.data;
			const response = yield call(api.setTablePayMethod, {
				pay_method,
				table_bill_id,
				total_members_to_pay,
			});
			yield put({
				type: SET_TABLE_DATA,
				key: 'tableData',
				value: JSON.parse(JSON.stringify(response.data.table)),
			});
			Basket.setBillPayData(response.data.table);

			if (response.data.table.pay_method == 0) {
				// const store = yield select();
				// let profile = store.profile.profile;
				// if (response.data.table.lead_user != profile) {
				//   yield call(forwardTo, '/table-opened');
				// } else {
				Basket.setTablePaymentAmount(response.data.table.total_due);
				yield call(forwardTo, '/table-overview');
				// }
			} else if (response.data.table.pay_method == 1) {
				yield call(forwardTo, '/split-bill');
			} else if (response.data.table.pay_method == 2) {
				Basket.setTablePaymentAmount(response.data.table.total_due);
				yield call(forwardTo, '/split-bill-by-amount');
			} else if (response.data.table.pay_method == 3) {
				yield call(forwardTo, '/split-bill-by-items');
			} else {
				yield call(forwardTo, '/pay-table');
			}
		});
	}
};

export const selectTableItemsFlow = function* () {
	while (true) {
		const action = yield take(SELECT_TABLE_ITEM);
		yield call(loading, function* () {
			const { tableNumber, businessLocationId, selected_item, profileId } = action.data;
			const response = yield call(api.postSlectItems, tableNumber, businessLocationId, {
				selected_item,
			});
			Basket.setBillPayData(response);
			yield put({
				type: SET_TABLE_DATA,
				key: 'tableData',
				value: JSON.parse(JSON.stringify(response)),
			});
			Basket.calculateItemsPrice(profileId);
		});
	}
};
export const sendTablePaymentEmailFlow = function* () {
	while (true) {
		const action = yield take(SEND_TABLE_PAYMENT_EMAIL);
		yield call(loading, function* () {
			const response = yield call(api.sendTablePaymentEmail, action.data);
			if (response.status === 'OK') {
				yield put({ type: SET_COMMON_PROP, key: 'paymentEmailSent', value: true });
				yield put(showToast('Payment Confirmation Email has been sent.', 'success'));
			}
		});
	}
};
export const sendTableBillFlow = function* () {
	while (true) {
		const { paymentType, paymentWebType, cb } = yield take(SEND_TABLE_BILL);
		yield call(loading, function* () {
			try {
				let billData = Basket.parseBillPayload(paymentType, paymentWebType);
				yield call(asyncStorage.setItem, 'bill_data', JSON.stringify(billData));
				const result = yield call(api.payTable, billData);
				let checkedIntentResult;
				let intentResult;

				if (result.error) {
					yield put(showToast(`${result.error.message ? result.error.message : 'Order create error'}`, 'warning'));
					forwardTo('/table-bill-pay', { completedTableOrder: false });
					return;
				} else {
					const tableData = result.data.table;
					const clientSecret = result.data.client_secret;
					const tablePaymentDataId = result.data.table_payment_uuid;
					yield call(asyncStorage.setItem, 'table_payment_data', JSON.stringify(tablePaymentDataId));

					if (!clientSecret || clientSecret === '') {
						yield put(showToast('Payment error.', 'danger'));
					} else {
						if (clientSecret === '-1') {
							// total for order is: 0
							intentResult = yield call(
								checkIntentResult,
								{
									status: 'succeeded',
								},
								true,
							);
							yield call(resetBasket, false, true);
						} else if (paymentType === 'webPayment') {
							yield put({ type: SET_ORDERS_PROP, key: 'clientSecret', value: clientSecret });

							if (cb) {
								yield call(cb, clientSecret, resetBasket, false, true);
							}
						} else {
							try {
								if (paymentType === 'google') {
									// pay with google pay
									intentResult = yield call(Stripe.payWithGooglePay, clientSecret, billData.payment_amount + billData.service_charge_applied);
									yield call(updatePaymentInfo, intentResult, paymentType, tableData);
									checkedIntentResult = yield call(checkIntentResult, intentResult, true);
								} else if (paymentType === 'apple') {
									// pay with apple pay
									const store = yield select();
									intentResult = yield call(Stripe.payWithApplePay, clientSecret, store.profile.profile, true);
									yield call(updatePaymentInfo, intentResult, paymentType, tableData);
									checkedIntentResult = yield call(checkIntentResult, intentResult, true);
								} else {
									// pay with regular payment card
									intentResult = yield call(Stripe.confirmPaymentIntent, clientSecret, {
										paymentMethodId: billData.payment_token,
									});
									checkedIntentResult = yield call(checkIntentResult, intentResult, true);
								}
								if (checkedIntentResult && checkedIntentResult.isValid) {
									yield call(resetBasket, false, true);
								}
								if (isDefined(intentResult) && intentResult.isValid) {
									tableData.amount_paid = intentResult.intentResult.amount;
									yield put({ type: SET_BILL_STATUS, billStatus: intentResult.intentResult });
									yield call(forwardTo, '/bill-completed', { completedOrder: true });
								}
							} catch (e) {
								yield put(showToast('Payment error.\n' + (e.message || ''), 'danger'));
							}
						}
					}
				}
			} catch (e) {
				yield put(showToast('Payment failed', 'danger'));
				forwardTo('/table-bill-pay', { completedTableOrder: false });
			}
		});
	}
};

export const bookDeliveryDriverFlow = function* () {
	while (true) {
		yield take(BOOK_DELIVERY_DRIVER);
		let driverAvailable = true;

		if (!Basket.validation_id && !isEmptyObject(Basket.getDeliveryAddress())) {
			yield put({ type: SET_COMMON_PROP, key: 'driverBookingPending', value: true });
			const basketAddress = Basket.getDeliveryAddress();
			const restaurant = Basket.getRestaurant();
			const address = [basketAddress.addressLine1, basketAddress.addressLine2, basketAddress.place, basketAddress.postalCode, restaurant?.additional_delivery_settings?.deliverect?.country]
				.filter(Boolean)
				.filter((el) => el !== '')
				.join(', ');
			const results = yield call(geocodeByAddress, address);
			const streetNumber = results[0].address_components.filter((el) => el.types.includes('street_number'))?.[0]?.long_name ?? '';
			const street = results[0].address_components.filter((el) => el.types.includes('route'))?.[0]?.long_name ?? '';
			const city = basketAddress.place;
			const { lat, lng } = yield call(getLatLng, results[0]);
			const store = yield select();
			const { profile } = store.profile;
			const data = {
				restaurant_id: restaurant.id,
				channelLinkId: restaurant?.additional_delivery_settings?.deliverect?.channelLinkId,
				deliveryTime: Basket.collection_time,
				packageSize: 'unknown',
				street: basketAddress.addressLine1,
				city: basketAddress.place,
				country: restaurant?.additional_delivery_settings?.deliverect?.country,
				postalCode: basketAddress.postalCode,
				coordinates: { coordinates: [lng, lat] },
				name: `${profile.first_name} ${profile.last_name}`,
				source: address,
				phone: profile.mobile,
			};
			const { validationId, available, price } = yield call(api.bookDriver, data);
			driverAvailable = available;
			if (driverAvailable) {
				Basket.setAdditionalAddressData({
					streetNumber,
					street,
					city,
				});
				Basket.setDeliverectDate({
					validation_id: validationId,
					channel_link_id: restaurant.additional_delivery_settings.deliverect.channelLinkId,
					channel_name: restaurant.additional_delivery_settings.deliverect.channelName,
				});
				if (price >= 0) {
					Basket.setDeliveryPrice(price);
				}
				yield put({ type: SET_COMMON_PROP, key: 'driverBookingPending', value: false });
				yield put({ type: SET_COMMON_PROP, key: 'driverBookingMessage', value: true });
				yield delay(2000);
				yield put({ type: SET_COMMON_PROP, key: 'driverBookingMessage', value: false });
			} else {
				yield put({
					type: SET_COMMON_MODAL,
					modal: 'driverUnavailableModal',
					value: true,
				});
			}
		}
		const driverCountdown = yield call(asyncStorage.getItem, 'driver_countdown');
		let remainingTime = driverCountdown ? driverCountdown : getConfig().flags.driverCountdown ? getConfig().flags.driverCountdown * 60 : 10 * 60;
		const timerTask = yield fork(timer, remainingTime);
		if (!driverAvailable) {
			yield cancel(timerTask);
			yield call(asyncStorage.removeItem, 'driver_countdown', remainingTime);
			yield put(updateDriverCountdown(0));
			Basket.setDeliverectDate({
				validation_id: null,
				channel_link_id: null,
				channel_name: null,
			});
		}
	}
};
function* timer(initialTime) {
	let remainingTime = initialTime;
	while (remainingTime > 0) {
		yield put(updateDriverCountdown(remainingTime));
		yield call(asyncStorage.setItem, 'driver_countdown', remainingTime);
		yield delay(1000);
		remainingTime -= 1;
	}
	yield put(updateDriverCountdown(0));
	yield call(asyncStorage.removeItem, 'driver_countdown', remainingTime);
	yield call(driverCallback);
}
function* driverCallback() {
	Basket.setDeliverectDate({
		validation_id: null,
		channel_link_id: null,
		channel_name: null,
	});

	yield put({ type: BOOK_DELIVERY_DRIVER });
}
